import CloseIcon from 'react-icons/lib/md/close';
import styled from 'styled-components';
import Button from 'components/atoms/Button';

const Wrapper = styled.div`
  border-radius: 2em;
  padding: 0.5em 2.5em 0.5em 0.8em;
  border: 0.125em solid ${({ theme }) => theme.colors.neutral4};
  margin: 0.5em;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
`;

const BadgeButton = styled(Button)`
  border-radius: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
`;

const AppliedFilterBadge = ({ name, value, onClose }) => {
  return (
    <Wrapper>
      <strong>{`${name}: `}</strong>
      {value}
      <BadgeButton neutral onClick={onClose}>
        <CloseIcon />
      </BadgeButton>
    </Wrapper>
  );
};

export default AppliedFilterBadge;
