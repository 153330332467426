import styled from 'styled-components';
import AppliedFilterBadge from './AppliedFilterBadge';

const Wrapper = styled.div`
  display: flex;
  margin: -0.5em -0.5em 0.5em;
  flex-wrap: wrap;
`;

const AppliedFilters = ({ appliedFilters, categoryOptions, onChangeFilters }) => {
  const badges = Object.keys(appliedFilters).reduce(
    (prev, appliedFilterId) => [
      ...prev,
      ...(() => {
        const appliedFilterValue = appliedFilters[appliedFilterId];
        const categoryOption = categoryOptions[appliedFilterId];

        if (categoryOption.filterType === 'MULTIPLE_SELECT') {
          const selectValues = appliedFilterValue
            .map((selectValueId) =>
              categoryOption.selectValues.find((selectValue) => selectValue.id === selectValueId),
            )
            .filter((selectValue) => !!selectValue);

          if (selectValues.length === 0) {
            return [];
          }

          const value =
            selectValues.length === 1 ? selectValues[0].value : `${selectValues.length} знач.`;

          return [
            <AppliedFilterBadge
              key={appliedFilterId}
              name={categoryOption.name}
              value={value}
              onClose={() => onChangeFilters({ ...appliedFilters, [appliedFilterId]: undefined })}
            />,
          ];
        }

        if (categoryOption.filterType === 'SINGLE_SELECT') {
          const value = categoryOption.selectValues.find(
            (selectValue) => selectValue.id === appliedFilterValue,
          );

          if (!value) {
            return [];
          }

          return [
            <AppliedFilterBadge
              key={appliedFilterId}
              name={categoryOption.name}
              value={value}
              onClose={() => onChangeFilters({ ...appliedFilters, [appliedFilterId]: undefined })}
            />,
          ];
        }

        if (categoryOption.filterType === 'RANGE') {
          return [];
        }

        return [];
      })(),
    ],
    [],
  );

  return <Wrapper>{badges}</Wrapper>;
};

export default AppliedFilters;
