import qs from 'qs';
import { useLocation } from 'react-router-dom';
import useQueryParams from './useQueryParams';

const ORDER_BY = {
  name_ASC: 'по наименованию (от А к Я)',
  name_DESC: 'по наименованию (от Я к А)',
  price_ASC: 'по цене (по возрастанию)',
  price_DESC: 'по цене (по убыванию)',
};

const ORDER_BY_LIST = [
  { key: 'name_ASC', value: ORDER_BY.name_ASC },
  { key: 'name_DESC', value: ORDER_BY.name_DESC },
  { key: 'price_ASC', value: ORDER_BY.price_ASC },
  { key: 'price_DESC', value: ORDER_BY.price_DESC },
];

const DEFAULT_ORDER_BY = 'name_ASC';

export default () => {
  const location = useLocation();
  const queryParams = useQueryParams();

  const rawOrderBy = queryParams.order;

  if (rawOrderBy && !ORDER_BY[rawOrderBy]) {
    return { orderBy: null };
  }

  if (rawOrderBy === DEFAULT_ORDER_BY) {
    return {
      redirectTo: {
        pathname: location.pathname,
        search: qs.stringify({ ...queryParams, order: undefined }, { arrayFormat: 'brackets' }),
      },
    };
  }

  const orderBy = rawOrderBy || DEFAULT_ORDER_BY;
  const [field, direction] = orderBy.split('_');
  const queryOrderBy = { [field]: direction.toLowerCase() };

  return {
    rawOrderBy,
    availableOrderBy: ORDER_BY_LIST,
    orderBy,
    queryOrderBy,
  };
};
