import { useMemo } from 'react';
import qs from 'qs';
import styled from 'styled-components';

import Button from 'components/atoms/Button';
import ButtonLink from 'components/atoms/ButtonLink';

import useQueryParams from '../useQueryParams';

import Option from './Option';

const Wrapper = styled.form`
  border: 0.125em solid ${({ theme }) => theme.colors.neutral4};
  padding: 0.68em;
  border-radius: 0.25em;
`;

const StyledOption = styled(Option)`
  margin-bottom: 1em;
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin-bottom: 0.5em;
`;

const StyledButtonLink = styled(ButtonLink)`
  width: 100%;
`;

const Filters = ({ className, category, appliedFilters, categoryOptions, onChangeFilters }) => {
  const queryParams = useQueryParams();
  const appliedFiltersString = useMemo(() => qs.stringify(appliedFilters), [appliedFilters]);
  const resetLinkTo = useMemo(
    () => ({
      pathname: `/catalog/${category.translit}`,
      search: qs.stringify({ ...queryParams, options: undefined, page: undefined }),
    }),
    [category, queryParams],
  );
  const showReset = Object.keys(appliedFilters).length > 0;

  function handleSubmit(event) {
    event.preventDefault();

    const formData = new FormData(event.target);

    const queryString = new URLSearchParams(formData).toString();
    const formQueryParams = qs.parse(queryString);

    if (!formQueryParams.options) {
      formQueryParams.options = undefined;
    }

    onChangeFilters(formQueryParams.options);
  }

  return (
    <Wrapper onSubmit={handleSubmit} className={className} key={appliedFiltersString}>
      {categoryOptions.map((option) => (
        <StyledOption
          {...option}
          value={appliedFilters && appliedFilters[option.id]}
          key={option.id}
        />
      ))}
      <StyledButton primary type="submit">
        Показать
      </StyledButton>
      {showReset && (
        <StyledButtonLink neutral to={resetLinkTo}>
          Сбросить
        </StyledButtonLink>
      )}
    </Wrapper>
  );
};

export default Filters;
