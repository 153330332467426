import { useQuery } from 'react-apollo';

import categoryQuery from 'graphql/queries/category.graphql';
import NotFoundPage from 'components/pages/NotFoundPage';
import LoadingPage from 'components/pages/LoadingPage';
import ErrorLoadingPage from 'components/pages/ErrorLoadingPage';

const CategoryInfoProvider = ({ children, translit }) => {
  const { loading, data, error } = useQuery(categoryQuery, {
    variables: {
      translit,
    },
  });

  if (loading) {
    return <LoadingPage />;
  }

  if (error) {
    return <ErrorLoadingPage />;
  }

  if (!data.category) {
    return <NotFoundPage />;
  }

  return children(data);
};

export default CategoryInfoProvider;
