import { useMemo } from 'react';
import useQueryParams from './useQueryParams';

export default (categoryOptions, skip) => {
  const queryParams = useQueryParams({ parseArrays: false });

  return useMemo(() => {
    if (skip) {
      return null;
    }

    const urlOptions = queryParams.options || {};

    return Object.keys(urlOptions).reduce((prev, curr) => {
      const categoryOption = categoryOptions[curr];

      const result = {
        ...prev,
      };

      if (categoryOption) {
        if (categoryOption.filterType === 'MULTIPLE_SELECT') {
          if (typeof urlOptions[curr] === 'string') {
            const selectValueIds = urlOptions[curr].split(',');

            const ids = selectValueIds.filter(
              (selectValueId) =>
                !!categoryOption.selectValues.find(
                  (selectValue) => selectValue.id === selectValueId,
                ),
            );

            if (ids.length > 0) {
              result[curr] = ids;
            }
          }
        } else if (categoryOption.filterType === 'RANGE') {
          if (urlOptions[curr].from) {
            const value = +urlOptions[curr].from.replace(',', '.').replace(/[^0-9.]+/g, '');

            if (!Number.isNaN(value)) {
              result[curr] = result[curr] || {};
              result[curr].from = value;
            }
          }

          if (urlOptions[curr].to) {
            const value = +urlOptions[curr].to.replace(',', '.').replace(/[^0-9.]+/g, '');

            if (!Number.isNaN(value)) {
              result[curr] = result[curr] || {};
              result[curr].to = value;
            }
          }
        } else if (categoryOption.filterType === 'SINGLE_SELECT') {
          const value = categoryOption.selectValues.find(
            (selectValue) => selectValue.id === urlOptions[curr],
          );

          if (value) {
            result[curr] = urlOptions[curr];
          }
        }
      }

      return result;
    }, {});
  }, [queryParams, categoryOptions, skip]);
};
