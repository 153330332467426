import styled from 'styled-components';
import Input from 'components/atoms/Input';

const Wrapper = styled.div``;

const Name = styled.div`
  font-weight: 600;
  margin-bottom: 0.3em;
`;

const ValuesList = styled.div`
  display: flex;
  flex-direction: column;
`;

const ValueWrapper = styled.label`
  display: flex;
  align-items: center;
  margin: 0.2em 0;
`;

const CheckboxInput = styled.input`
  margin: 0 0.714em 0 0;
  flex: none;
`;

const RadioInput = CheckboxInput;

const RangeInputsWrapper = styled.div`
  display: flex;
  margin: 0 -1em;
`;

const RangeInput = styled(Input)`
  margin: 0.2em 1em;
`;

const Option = ({ id, name, filterType, selectValues, value, className }) => (
  <Wrapper className={className}>
    <Name>{name}</Name>
    {filterType === 'MULTIPLE_SELECT' && (
      <ValuesList>
        {selectValues.map((selectValue) => (
          <ValueWrapper key={selectValue.id}>
            <CheckboxInput
              type="checkbox"
              name={`options[${id}][]`}
              value={selectValue.id}
              defaultChecked={(!!value && value.includes(selectValue.id) && 'checked') || false}
            />
            {selectValue.value}
          </ValueWrapper>
        ))}
      </ValuesList>
    )}

    {filterType === 'SINGLE_SELECT' && (
      <ValuesList>
        {selectValues.map((selectValue) => (
          <ValueWrapper key={selectValue.id}>
            <RadioInput
              type="radio"
              name={`options[${id}]`}
              value={selectValue.id}
              defaultChecked={(!!value && value === selectValue.id && 'checked') || false}
            />
            {selectValue.value}
          </ValueWrapper>
        ))}
        <ValueWrapper>
          <RadioInput
            type="radio"
            name={`options[${id}]`}
            value=""
            defaultChecked={(!value && 'checked') || false}
          />
          Неважно
        </ValueWrapper>
      </ValuesList>
    )}

    {filterType === 'RANGE' && (
      <RangeInputsWrapper>
        <RangeInput
          placeholder="От"
          defaultValue={(!!value && value.from) || undefined}
          name={`options[${id}][from]`}
        />
        <RangeInput
          placeholder="До"
          defaultValue={(!!value && value.to) || undefined}
          name={`options[${id}][to]`}
        />
      </RangeInputsWrapper>
    )}
  </Wrapper>
);

export default Option;
