import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

export default (opts = {}) => {
  const location = useLocation();

  return useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        ...opts,
      }),
    [location.search, opts],
  );
};
