import { useMemo } from 'react';
import styled from 'styled-components';

import ItemsList from 'components/molecules/ItemsList';
import Pagination from 'components/molecules/Pagination';
import TextFormatter from 'components/atoms/TextFormatter';

import InfoBar from './InfoBar';
import AppliedFilters from './AppliedFilters';
import useCategoryOptions from '../useCategoryOptions';
import SubcategoriesCards from './SubcategoriesCards';
import PredefinedOptionsCombinationsCards from './PredefinedOptionsCombinationsCards';

const ItemsWrapper = styled.div`
  margin-bottom: 3.5em;
`;

const StyledInfoBar = styled(InfoBar)`
  margin-bottom: 1em;
`;

const StyledItemsList = styled(ItemsList)``;

const StyledPagination = styled(Pagination)`
  margin-top: 1.5em;
`;

const Description = styled(TextFormatter)`
  line-height: 1.5;

  p:first-child {
    margin-top: 0;
  }
`;

const StyledSubcategoriesCards = styled(SubcategoriesCards)`
  margin-bottom: 2em;
`;

const StyledPredefinedOptionsCombinationsCards = styled(PredefinedOptionsCombinationsCards)`
  margin-bottom: 2em;
`;

const MainContent = ({
  isAsideShown,
  onChangeOrderBy,
  orderBy,
  availableOrderBy,
  count,
  items,
  totalPages,
  page,
  appliedFilters,
  onChangeFilters,
  category,
  currentPredefinedOptionsCombination,
  showItems,
  showSubcategories,
  showPredefinedOptionsCombinations,
}) => {
  const categoryOptionsList = useCategoryOptions(category);
  const categoryOptions = useMemo(
    () => categoryOptionsList.reduce((prev, curr) => ({ ...prev, [curr.id]: curr }), {}),
    [categoryOptionsList],
  );

  const isFiltersApplied = Object.keys(appliedFilters).length > 0;

  const predefinedOptionsCombinations = useMemo(
    () =>
      category.predefinedOptionsCombinations.filter(
        (predefinedOptionsCombination) => !!predefinedOptionsCombination.showCard,
      ),
    [category.predefinedOptionsCombinations],
  );

  return (
    <>
      {showItems && (
        <ItemsWrapper>
          <StyledInfoBar
            onChangeOrderBy={onChangeOrderBy}
            orderBy={orderBy}
            availableOrderBy={availableOrderBy}
            count={count}
          />
          <AppliedFilters
            appliedFilters={appliedFilters}
            categoryOptions={categoryOptions}
            onChangeFilters={onChangeFilters}
          />
          <StyledItemsList cols={isAsideShown ? [2, 2, 3] : [2, 3, 4]} items={items} />
          <StyledPagination total={totalPages} currentPage={page} />
        </ItemsWrapper>
      )}
      {showSubcategories && category.subcategories.length > 0 && (
        <StyledSubcategoriesCards
          isAsideShown={isAsideShown}
          subcategories={category.subcategories}
        />
      )}
      {showPredefinedOptionsCombinations && predefinedOptionsCombinations.length > 0 && (
        <StyledPredefinedOptionsCombinationsCards
          isAsideShown={isAsideShown}
          category={category}
          predefinedOptionsCombinations={predefinedOptionsCombinations}
        />
      )}
      {page === 1 && !isFiltersApplied && category.description && (
        <Description text={category.description} />
      )}
      {page === 1 &&
        currentPredefinedOptionsCombination &&
        currentPredefinedOptionsCombination.description && (
          <Description text={currentPredefinedOptionsCombination.description} />
        )}
    </>
  );
};

export default MainContent;
