import styled from 'styled-components';
import Select from 'components/atoms/Select';

const Wrapper = styled.div`
  border-radius: 0.25em;
  background-color: ${({ theme }) => theme.colors.neutral5};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

const Count = styled.div`
  padding: 0.75em 1em;
  color: ${({ theme }) => theme.colors.neutral3};
`;

const OrderBy = styled.div`
  font-weight: 600;
  padding: 0.75em 1em;
`;

const OrderBySelect = styled(Select)`
  display: inline-block;
  border: none;
  border-bottom: 0.15em dashed ${({ theme }) => theme.colors.primary};
  padding: 0;
  border-radius: 0;
  appearance: none;
  color: ${({ theme }) => theme.colors.primary};

  option {
    color: ${({ theme }) => theme.colors.text};
  }

  &:active,
  &:focus {
    background: none;
  }
`;

const InfoBar = ({ className, onChangeOrderBy, orderBy, availableOrderBy, count }) => {
  return (
    <Wrapper className={className}>
      <Count>{`Товаров всего: ${count}`}</Count>
      <OrderBy>
        {`Сортировать `}
        <OrderBySelect value={orderBy} onChange={onChangeOrderBy}>
          {availableOrderBy.map(({ value, key }) => (
            <option value={key} key={key}>
              {value}
            </option>
          ))}
        </OrderBySelect>
      </OrderBy>
    </Wrapper>
  );
};

export default InfoBar;
