import { useMemo } from 'react';

export default (appliedFilters, categoryOptions) =>
  useMemo(() => {
    const conditions = Object.keys(appliedFilters)
      .map((optionId) => {
        const value = appliedFilters[optionId];
        const categoryOption = categoryOptions[optionId];

        if (categoryOption.filterType === 'MULTIPLE_SELECT') {
          return {
            itemOptionsConnections: {
              some: {
                AND: [
                  { option: { id: { equals: optionId } } },
                  {
                    singleSelectValue: {
                      OR: value.map((val) => ({
                        selectOptionValue: { id: { equals: val } },
                      })),
                    },
                  },
                ],
              },
            },
          };
        }

        if (categoryOption.filterType === 'SINGLE_SELECT') {
          return {
            itemOptionsConnections: {
              some: {
                AND: [
                  { option: { id: { equals: optionId } } },
                  {
                    singleSelectValue: {
                      selectOptionValue: { id: { equals: value } },
                    },
                  },
                ],
              },
            },
          };
        }

        if (categoryOption.filterType === 'RANGE' && categoryOption.itemValueType === 'SPECIFIC') {
          const filter = {
            itemOptionsConnections: {
              some: {
                AND: [
                  { option: { id: { equals: optionId } } },
                  {
                    specificValue: {},
                  },
                ],
              },
            },
          };

          if (value.from || value.to) {
            filter.itemOptionsConnections.some.AND[1].specificValue.value =
              filter.itemOptionsConnections.some.AND[1].specificValue.value || {};
          }

          if (value.from) {
            filter.itemOptionsConnections.some.AND[1].specificValue.value.gte = value.from;
          }

          if (value.to) {
            filter.itemOptionsConnections.some.AND[1].specificValue.value.lte = value.to;
          }

          return filter;
        }

        if (categoryOption.filterType === 'RANGE' && categoryOption.itemValueType === 'RANGE') {
          const filter = {
            itemOptionsConnections: {
              some: {
                AND: [
                  { option: { id: optionId } },
                  {
                    rangeValue: {},
                  },
                ],
              },
            },
          };

          if (value.from) {
            filter.itemOptionsConnections.some.AND[1].rangeValue.toValue = { gte: value.from };
          }

          if (value.to) {
            filter.itemOptionsConnections.some.AND[1].rangeValue.fromValue = { lte: value.to };
          }

          return filter;
        }

        return undefined;
      })
      .filter((c) => !!c);

    return conditions.length > 0 ? { AND: conditions } : undefined;
  }, [appliedFilters, categoryOptions]);
