import qs from 'qs';
import { useLocation } from 'react-router-dom';
import useQueryParams from './useQueryParams';

export default () => {
  const location = useLocation();
  const queryParams = useQueryParams();

  const rawPage = queryParams.page;

  if (typeof rawPage !== 'undefined' && (isNaN(+rawPage) || +rawPage <= 0)) {
    return { page: null };
  }

  if (rawPage === '1') {
    return {
      redirectTo: {
        pathname: location.pathname,
        search: qs.stringify({ ...queryParams, page: undefined }, { arrayFormat: 'brackets' }),
      },
    };
  }

  return { page: +rawPage || 1 };
};
