import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';

import MainTemplate from 'components/templates/MainTemplate';
import Breadcrumbs from 'components/molecules/Breadcrumbs';
import Redirect from 'components/atoms/Redirect';

import Aside from './Aside';
import MainContent from './MainContent';
import CategoryInfoProvider from './CategoryInfoProvider';
import CategoryItemsProvider from './CategoryItemsProvider';

import useRedirectFromOldOptions from './useRedirectFromOldOptions';

const Title = styled.h1`
  font-size: 2em;
  font-weight: normal;
  margin-top: 0.3em;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    flex-direction: row;
    margin: 0 -0.625em;
  }
`;

const StyledAside = styled(Aside)`
  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    width: 25%;
    flex: none;
    padding: 0 0.625em;
  }
`;

const Main = styled.main`
  max-width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    flex: 1 1 auto;
    padding: 0 0.625em;
  }
`;

const CatalogCategoryPageNew = ({
  match: {
    params: { translit, slug },
  },
}) => {
  const redirectTo = useRedirectFromOldOptions();

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <CategoryInfoProvider translit={translit}>
      {({ category }) => (
        <CategoryItemsProvider category={category} slug={slug}>
          {({
            showItems,
            showSubcategories,
            showPredefinedOptionsCombinations,
            loading,
            page,
            count,
            totalPages,
            items,
            onChangeOrderBy,
            orderBy,
            availableOrderBy,
            onChangeFilters,
            appliedFilters,
            currentPredefinedOptionsCombination,
            isAsideShown,
          }) => {
            const title =
              (currentPredefinedOptionsCombination && currentPredefinedOptionsCombination.title) ||
              category.title ||
              category.name;
            const h1 =
              (currentPredefinedOptionsCombination && currentPredefinedOptionsCombination.h1) ||
              category.h1 ||
              category.name;
            const metaKeywords =
              (currentPredefinedOptionsCombination &&
                currentPredefinedOptionsCombination.metaKeywords) ||
              category.metaKeywords;
            const metaDescription =
              (currentPredefinedOptionsCombination &&
                currentPredefinedOptionsCombination.metaDescription) ||
              category.metaDescription;

            return (
              <MainTemplate>
                <Helmet>
                  <title>
                    {title}
                    {page > 1 ? ` — страница ${page}` : ''}
                  </title>
                  {metaKeywords && <meta name="keywords" content={metaKeywords} />}
                  {metaDescription && <meta name="description" content={metaDescription} />}
                </Helmet>
                <Breadcrumbs
                  links={[
                    {
                      link: '/catalog',
                      text: 'Каталог',
                    },
                    ...category.ancestors.map((ancestor) => ({
                      link: `/catalog/${ancestor.translit}`,
                      text: ancestor.name,
                    })),
                  ]}
                />
                <Title>{h1}</Title>
                <Wrapper>
                  {isAsideShown && (
                    <StyledAside
                      showSubcategories={showSubcategories}
                      category={category}
                      appliedFilters={appliedFilters}
                      onChangeFilters={onChangeFilters}
                    />
                  )}
                  <Main>
                    {loading && <div>Загрузка...</div>}
                    {!loading && (
                      <MainContent
                        isAsideShown={isAsideShown}
                        availableOrderBy={availableOrderBy}
                        orderBy={orderBy}
                        count={count}
                        onChangeOrderBy={onChangeOrderBy}
                        items={items}
                        totalPages={totalPages}
                        page={page}
                        appliedFilters={appliedFilters}
                        onChangeFilters={onChangeFilters}
                        currentPredefinedOptionsCombination={currentPredefinedOptionsCombination}
                        category={category}
                        showItems={showItems}
                        showSubcategories={showSubcategories}
                        showPredefinedOptionsCombinations={showPredefinedOptionsCombinations}
                      />
                    )}
                  </Main>
                </Wrapper>
              </MainTemplate>
            );
          }}
        </CategoryItemsProvider>
      )}
    </CategoryInfoProvider>
  );
};

export default CatalogCategoryPageNew;
