import { useMemo } from 'react';
import { useQuery } from 'react-apollo';
import qs from 'qs';
import { useLocation, useHistory } from 'react-router-dom';

import categoryItemsQuery from 'graphql/queries/categoryItems.graphql';
import Redirect from 'components/atoms/Redirect';
import NotFoundPage from 'components/pages/NotFoundPage';
import ErrorLoadingPage from 'components/pages/ErrorLoadingPage';
import LoadingPage from 'components/pages/LoadingPage';

import usePage from './usePage';
import useOrderBy from './useOrderBy';
import useQueryParams from './useQueryParams';
import useParsedFilters from './useParsedFilters';
import useCategoryOptions from './useCategoryOptions';
import useItemWhereInput from './useItemWhereInput';
import useCurrentPredefinedOptionsCombination from './useCurrentPredefinedOptionsCombination';
import useSlugRedirect from './useSlugRedirect';
import getPredefinedOptionsCombinationByParsedFilters from './getPredefinedOptionsCombinationByParsedFilters';

const ITEMS_PER_PAGE_3_COLS = 30;
const ITEMS_PER_PAGE_4_COLS = 32;

const CategoryItemsProvider = ({ children, category, slug }) => {
  const location = useLocation();
  const history = useHistory();
  const queryParams = useQueryParams();
  const { page, redirectTo: redirectToPage } = usePage();
  const {
    availableOrderBy,
    rawOrderBy,
    orderBy,
    queryOrderBy,
    redirectTo: redirectToOrderBy,
  } = useOrderBy();
  const categoryOptionsList = useCategoryOptions(category);
  const categoryOptionsMap = useMemo(
    () => categoryOptionsList.reduce((prev, curr) => ({ ...prev, [curr.id]: curr }), {}),
    [categoryOptionsList],
  );
  const parsedFilters = useParsedFilters(categoryOptionsMap, !!slug);
  const {
    currentPredefinedOptionsCombination,
    appliedFilters,
  } = useCurrentPredefinedOptionsCombination(
    parsedFilters,
    slug,
    category.predefinedOptionsCombinations,
  );
  const itemWhereInput = useItemWhereInput(appliedFilters, categoryOptionsMap);
  const redirectToSlug = useSlugRedirect(currentPredefinedOptionsCombination, slug, category);

  const redirectTo = redirectToPage || redirectToOrderBy || redirectToSlug;

  const showItems = category.showItems || Object.keys(appliedFilters).length > 0;
  const showSubcategories =
    category.showSubcategories && Object.keys(appliedFilters).length === 0 && page === 1;
  const showPredefinedOptionsCombinations =
    category.showPredefinedOptionsCombinations &&
    Object.keys(appliedFilters).length === 0 &&
    page === 1;

  const isAsideShown =
    (category.subcategories.length > 0 && !showSubcategories) ||
    category.optionsConnections.length > 0;

  const perPage =
    category.perPage !== null && category.perPage > 0
      ? category.perPage
      : isAsideShown
      ? ITEMS_PER_PAGE_3_COLS
      : ITEMS_PER_PAGE_4_COLS;

  const { loading, data, error } = useQuery(categoryItemsQuery, {
    skip: !!redirectTo || !page || !orderBy || !showItems,
    variables: {
      translit: category.translit,
      itemWhereInput,
      orderBy: queryOrderBy,
      offset: (page - 1) * perPage,
      itemsPerPage: perPage,
    },
  });

  function onChangeOrderBy(event) {
    history.push({
      pathname: location.pathname,
      search: qs.stringify(
        { ...queryParams, order: event.target.value, page: undefined },
        { arrayFormat: 'brackets' },
      ),
    });
  }

  function onChangeFilters(newOptions) {
    const predefinedOptionsCombination = getPredefinedOptionsCombinationByParsedFilters(
      category.predefinedOptionsCombinations,
      newOptions || {},
    );

    const filterSlug =
      predefinedOptionsCombination && predefinedOptionsCombination.slug
        ? `/filter-${predefinedOptionsCombination.slug}`
        : '';

    history.push({
      pathname: `/catalog/${category.translit}${filterSlug}`,
      search: qs.stringify(
        {
          ...queryParams,
          options: filterSlug ? undefined : newOptions,
          page: undefined,
          order: category.showItems ? queryParams.order : undefined,
        },
        { arrayFormat: 'comma' },
      ),
    });
  }

  if (slug && !currentPredefinedOptionsCombination) {
    return <NotFoundPage />;
  }

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  if (!page || !orderBy || (!showItems && rawOrderBy)) {
    return <NotFoundPage />;
  }

  if (loading && (!data || !data.category)) {
    return <LoadingPage />;
  }

  if (error) {
    return <ErrorLoadingPage />;
  }

  const count = showItems ? data.category.allItemsConnectionAggregated.aggregate.count : 0;

  const totalPages = Math.max(Math.ceil(count / perPage), 1);

  if (page > totalPages) {
    return <NotFoundPage />;
  }

  const items = showItems ? data.category.allItemsConnection.edges.map((edge) => edge.node) : [];

  return children({
    showItems,
    showSubcategories,
    showPredefinedOptionsCombinations,
    totalPages,
    page,
    count,
    items,
    loading,
    onChangeOrderBy,
    orderBy,
    availableOrderBy,
    appliedFilters,
    onChangeFilters,
    currentPredefinedOptionsCombination,
    isAsideShown,
  });
};

export default CategoryItemsProvider;
