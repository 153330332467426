import { useMemo } from 'react';
import getPredefinedOptionsCombinationByParsedFilters from './getPredefinedOptionsCombinationByParsedFilters';

export default (parsedFilters, slug, predefinedOptionsCombinations) => {
  const currentPredefinedOptionsCombination = useMemo(() => {
    if (slug) {
      return predefinedOptionsCombinations.find(
        (predefinedOptionsCombination) => predefinedOptionsCombination.slug === slug,
      );
    }

    return getPredefinedOptionsCombinationByParsedFilters(
      predefinedOptionsCombinations,
      parsedFilters,
    );
  }, [parsedFilters, predefinedOptionsCombinations, slug]);

  const appliedFilters = useMemo(() => {
    if (!currentPredefinedOptionsCombination) {
      return parsedFilters;
    }

    return currentPredefinedOptionsCombination.optionsWithValues.reduce(
      (prev, curr) => ({
        ...prev,
        [curr.option.id]: (() => {
          if (curr.option.filterType === 'MULTIPLE_SELECT') {
            return curr.multipleSelectValues.map((multipleSelectValue) => multipleSelectValue.id);
          }

          if (curr.option.filterType === 'SINGLE_SELECT') {
            return curr.singleSelectValue.id;
          }

          if (curr.option.filterType === 'RANGE') {
            // ToDo: это очень редкий кейс. Т.е. использовать предопределённые комбинации фильтров с типом RANGE пока не планируется
            return undefined;
          }

          return undefined;
        })(),
      }),
      {},
    );
  }, [parsedFilters, currentPredefinedOptionsCombination]);

  return { currentPredefinedOptionsCombination, appliedFilters };
};
