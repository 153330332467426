import { useState } from 'react';
import styled from 'styled-components';
import CaretIcon from 'react-icons/lib/fa/caret-down';
import Button from 'components/atoms/Button';
import CategoriesList from 'components/molecules/CategoriesList';
import Filters from './Filters';

import useCategoryOptions from '../useCategoryOptions';

const StyledButton = styled(Button)`
  display: block;
  width: 100%;
  margin-bottom: 0.5em;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    display: none;
  }
`;

const StyledCaretIcon = styled(({ rotate, ...rest }) => <CaretIcon {...rest} />)`
  display: inline-block;
  transform: rotate(${({ rotate }) => (rotate ? -180 : 0)}deg);
`;

const StyledCategoriesList = styled(CategoriesList)`
  display: ${({ showOnMobile }) => (showOnMobile ? 'block' : 'none')};
  margin-bottom: 1em;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    display: block;
  }
`;

const StyledFilters = styled(Filters)`
  display: ${({ showOnMobile }) => (showOnMobile ? 'block' : 'none')};
  margin-bottom: 1em;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    display: block;
  }
`;

const Aside = ({
  className,
  category,
  showSubcategories: showSubcategoriesAsCards,
  appliedFilters,
  onChangeFilters,
}) => {
  const showSubcategories = !showSubcategoriesAsCards;

  const [isSubcategoriesShown, setShowSubcategories] = useState(false);
  const [showFilters, setShowFilters] = useState(false);

  const categoryOptions = useCategoryOptions(category);

  function toggleSubcategories() {
    setShowSubcategories(!isSubcategoriesShown);
  }

  function toggleFilters() {
    setShowFilters(!showFilters);
  }

  return (
    <div className={className}>
      {category.subcategories.length > 0 && showSubcategories && (
        <div>
          <StyledButton
            neutral={!isSubcategoriesShown}
            primary={isSubcategoriesShown}
            onClick={toggleSubcategories}
          >
            Категории <StyledCaretIcon rotate={isSubcategoriesShown} />
          </StyledButton>

          <StyledCategoriesList
            categories={category.subcategories}
            showOnMobile={isSubcategoriesShown}
          />
        </div>
      )}
      {category.optionsConnections.length > 0 && (
        <div>
          {showSubcategories && (
            <StyledButton neutral={!showFilters} primary={showFilters} onClick={toggleFilters}>
              Фильтры <StyledCaretIcon rotate={showFilters} />
            </StyledButton>
          )}
          <StyledFilters
            category={category}
            categoryOptions={categoryOptions}
            appliedFilters={appliedFilters}
            showOnMobile={!showSubcategories || showFilters}
            onChangeFilters={onChangeFilters}
          />
        </div>
      )}
    </div>
  );
};

export default Aside;
