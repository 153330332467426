export default (predefinedOptionsCombinations, parsedFilters) => {
  if (predefinedOptionsCombinations.length === 0) {
    return null;
  }

  const optionIds = Object.keys(parsedFilters);

  if (optionIds.length === 0) {
    return null;
  }

  for (let i = 0; i < predefinedOptionsCombinations.length; i += 1) {
    const predefinedOptionsCombination = predefinedOptionsCombinations[i];

    if (
      predefinedOptionsCombination.optionsWithValues.length > 0 &&
      optionIds.length === predefinedOptionsCombination.optionsWithValues.length
    ) {
      let isCurrentPredefinedOptionsCombination = true;

      for (let j = 0; j < predefinedOptionsCombination.optionsWithValues.length; j += 1) {
        const optionFromPredefinedOptionsCombination =
          predefinedOptionsCombination.optionsWithValues[j].option;

        if (optionFromPredefinedOptionsCombination.filterType === 'MULTIPLE_SELECT') {
          const multipleSelectValuesFromCombination =
            predefinedOptionsCombination.optionsWithValues[j].multipleSelectValues;

          const multipleSelectValuesFromUser =
            parsedFilters[optionFromPredefinedOptionsCombination.id] || null;

          if (
            !multipleSelectValuesFromUser ||
            multipleSelectValuesFromCombination.length !== multipleSelectValuesFromUser.length ||
            multipleSelectValuesFromCombination.filter((x) =>
              multipleSelectValuesFromUser.includes(x.id),
            ).length !== multipleSelectValuesFromUser.length
          ) {
            isCurrentPredefinedOptionsCombination = false;
          }
        } else if (optionFromPredefinedOptionsCombination.filterType === 'SINGLE_SELECT') {
          isCurrentPredefinedOptionsCombination =
            parsedFilters[optionFromPredefinedOptionsCombination.id] &&
            predefinedOptionsCombination.optionsWithValues[j].singleSelectValue.id ===
              parsedFilters[optionFromPredefinedOptionsCombination.id];
        } else if (optionFromPredefinedOptionsCombination.filterType === 'RANGE') {
          // ToDo: это очень редкий кейс. Т.е. использовать предопределённые комбинации фильтров с типом RANGE пока не планируется
          isCurrentPredefinedOptionsCombination = false;
        } else {
          isCurrentPredefinedOptionsCombination = false;
        }
      }

      if (isCurrentPredefinedOptionsCombination) {
        return predefinedOptionsCombination;
      }
    }
  }

  return null;
};
