import { useMemo } from 'react';
import useQueryParams from './useQueryParams';
import qs from 'qs';
import { useLocation } from 'react-router-dom';

const getNewOptionValue = (oldValue) => {
  if (oldValue.multipleSelectValues) {
    return oldValue.multipleSelectValues;
  }

  if (oldValue.value) {
    return oldValue.value;
  }

  if (oldValue.from || oldValue.to) {
    return oldValue;
  }

  if (oldValue.intersectionFrom || oldValue.intersectionTo) {
    const result = {};

    if (oldValue.intersectionFrom) {
      result.from = oldValue.intersectionFrom;
    }

    if (oldValue.intersectionTo) {
      result.to = oldValue.intersectionTo;
    }

    return result;
  }

  return null;
};

export default () => {
  const location = useLocation();
  const queryParams = useQueryParams();

  return useMemo(() => {
    const { options } = queryParams;

    if (!options) {
      return null;
    }

    const optionIds = Object.keys(options);

    const result = optionIds.reduce((prev, curr) => {
      const newOptionValue = getNewOptionValue(options[curr]);

      const r = {
        ...prev,
      };

      if (newOptionValue) {
        r[curr] = newOptionValue;
      }

      return r;
    }, {});

    if (Object.keys(result).length === 0) {
      return null;
    }

    return {
      pathname: location.pathname,
      search: qs.stringify(
        {
          ...queryParams,
          options: result,
        },
        { arrayFormat: 'comma' },
      ),
    };
  }, [queryParams, location.pathname]);
};
