import styled from 'styled-components';
import { lighten, transparentize } from 'polished';
import ArrowIcon from 'react-icons/lib/md/arrow-forward';
import Link from 'components/atoms/Link';
import Button from 'components/atoms/Button';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    margin: 0 -0.625em;
  }
`;

const CategoriesList = styled.nav`
  display: flex;
  flex-wrap: wrap;
  margin: -0.5em;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    flex: none;
    padding: 0 0.625em;
  }
`;

const CategoriesListItem = styled.div`
  position: relative;
  border-radius: 0.25em;
  background-color: ${({ theme }) => theme.colors.neutral4};
  padding: 1em;
  border-left: 0.2em solid ${({ theme }) => theme.colors.primary};
  display: flex;
  flex-direction: column;

  margin: 0.5em;
  width: calc(100% - 1em);

  @media (min-width: ${({ theme }) => theme.breakpoints[0]}em) {
    width: ${({ cols }) => `calc((100% - ${cols[0]}em) / ${cols[0]})`};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    width: ${({ cols }) => `calc((100% - ${cols[1]}em) / ${cols[1]})`};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}em) {
    width: ${({ cols }) => `calc((100% - ${cols[2]}em) / ${cols[2]})`};
  }

  &:hover {
    background-color: ${({ theme }) => lighten(0.2, theme.colors.primary)};
  }
`;

const SubcategoryTitle = styled(Link)`
  text-decoration: none;
  margin-bottom: 0.5em;
  font-size: 1.3em;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

const SubcategoryDescription = styled.p`
  margin: 0 0 1em;
  line-height: 1.2em;
  color: ${({ theme }) => theme.colors.neutral3};
`;

const GoToSubcategoryButton = styled(Button)`
  margin-top: auto;
  align-self: flex-end;
  background-color: ${({ theme }) => transparentize(0.4, theme.colors.primary)};
  padding: 0.5em 0.6em;
`;

const ButtonText = styled.span`
  display: inline;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    display: none;
  }

  ${CategoriesListItem}:hover & {
    display: inline;
  }
`;

const SubcategoriesCards = ({ subcategories, isAsideShown, className }) => (
  <Wrapper className={className}>
    <CategoriesList>
      {subcategories.map((subcategory) => (
        <CategoriesListItem key={subcategory.id} cols={isAsideShown ? [2, 2, 3] : [2, 3, 4]}>
          <SubcategoryTitle to={`/catalog/${subcategory.translit}`}>
            {subcategory.name}
          </SubcategoryTitle>
          {subcategory.shortDescription && (
            <SubcategoryDescription>{subcategory.shortDescription}</SubcategoryDescription>
          )}
          <GoToSubcategoryButton primary>
            <ButtonText>Перейти </ButtonText>
            <ArrowIcon />
          </GoToSubcategoryButton>
        </CategoriesListItem>
      ))}
    </CategoriesList>
  </Wrapper>
);

export default SubcategoriesCards;
