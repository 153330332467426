import qs from 'qs';
import useQueryParams from './useQueryParams';

export default (currentPredefinedOptionsCombination, slug, category) => {
  const queryParams = useQueryParams();

  if (!currentPredefinedOptionsCombination || !currentPredefinedOptionsCombination.slug) {
    return null;
  }

  if (slug === currentPredefinedOptionsCombination.slug) {
    return null;
  }

  return {
    pathname: `/catalog/${category.translit}/filter-${currentPredefinedOptionsCombination.slug}`,
    search: qs.stringify({ ...queryParams, options: undefined }, { arrayFormat: 'brackets' }),
  };
};
